import { Directive, TemplateRef, inject } from '@angular/core';

@Directive({
	selector: '[bpGetStartedFormSubmitBtn]',
	standalone: true,
})
export class GetStartedFormSubmitBtnDirective {

	readonly templateRef = inject(TemplateRef);

}
