import { forEach, get, isArray, isEmpty, isEqual, isFunction, isPlainObject, mapValues, pickBy, isNil } from 'lodash-es';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { auditTime, filter, map, skipWhile, startWith, switchMap } from 'rxjs/operators';

import { OnInit, Directive, Input, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { has } from '@bp/shared/utilities/core';
import {
	DTO, MetadataEntity, PropertyMetadata, FormSchemeArray, FormScheme, ClassMetadata, FormSchemeAbstractControlOptions, MetadataEntityFormControls
} from '@bp/shared/models/metadata';
import { NonFunctionProperties, NonFunctionPropertyNames } from '@bp/shared/typings';

import { takeUntilDestroyed } from '@bp/frontend/models/common';
import { FormBaseComponent } from '@bp/frontend/components/core';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

@Directive()
export abstract class FormMetadataEntityBaseComponent<TMetadataEntity extends MetadataEntity>
	extends FormBaseComponent<TMetadataEntity, MetadataEntityFormControls<TMetadataEntity>>
	implements OnChanges, OnInit {

	protected readonly _entity$ = new BehaviorSubject<TMetadataEntity | null>(null);

	@Input() metadata!: ClassMetadata<TMetadataEntity>;

	@Output('entityChange') readonly entityChange$ = this._entity$
		.pipe(
			auditTime(50),
			skipWhile((entity, index) => entity === null && index === 0 || entity === this.entitySetExternally),
		);

	@Input() entity!: TMetadataEntity | null;

	@Input() factory!: (v?: DTO<TMetadataEntity>) => TMetadataEntity;

	@Input() formScheme?: FormScheme<TMetadataEntity> | null; // null means no form should be setup

	entitySetExternally: TMetadataEntity | null = null;

	constructor() {
		super();

		this.__onFormGroupChangeBuildEntityAndEmitChange();
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		if (changes.entity) {
			this.entitySetExternally = changes.entity.currentValue ?? null;

			this._setEntity(changes.entity.currentValue ?? null);
		}

		if (changes.form && this.formScheme)
			this.__setControlsToFormSetExternally();

		if (changes.entity && (!changes.form || !this.formScheme))
			this.__updateFormOnEntityChangedExternally();
	}

	ngOnInit(): void {
		this.__assertFactoryIsProvided();
	}

	private __updateFormOnEntityChangedExternally(): void {
		if (this.formScheme === null)
			return;

		if (this.entity && this.form && this.formScheme) {
			this._repopulateFormBasedOnScheme(
				this.form,
				this.formScheme,
				this.entity,
			);
		} else
			this._setupForm(this.formScheme!);
	}

	private __setControlsToFormSetExternally(): void {
		if (!this.formScheme)
			return;

		const form = this._buildFormGroupBasedOnFormScheme(
			this.formScheme,
			this.entity ?? this.factory(),
		);

		forEach(this.form!.controls, (control, controlName) => void this.form!.removeControl(controlName));

		forEach(form.controls, (control, controlName) => void this.form!.setControl(controlName, control));
	}

	setFormScheme(scheme: FormScheme<TMetadataEntity>): void {
		this.formScheme = scheme;
	}

	getFormScheme(): FormScheme<TMetadataEntity> {
		this.__assertFormSchemeIsProvided(this.formScheme!);

		return this.formScheme!;
	}

	getControlByPropertyMetadata(md: PropertyMetadata): UntypedFormControl | null {
		return <UntypedFormControl | undefined> this.form!.controls[md.property] ?? null;
	}

	ensurePropertyName(
		this: void,
		property: NonFunctionPropertyNames<TMetadataEntity>,
	): string {
		return <string>property;
	}

	ensurePropertyNames(
		this: void,
		propertyNames: NonFunctionPropertyNames<TMetadataEntity>[],
	): NonFunctionPropertyNames<TMetadataEntity>[] {
		return propertyNames;
	}

	patchForm(properties: Partial<NonFunctionProperties<TMetadataEntity>>): void {
		this.form!.patchValue(properties);
	}

	getFormGroupFormControl(formGroup: UntypedFormGroup, property: string): UntypedFormControl | null {
		return <UntypedFormControl | null> formGroup.get(property);
	}

	protected _setEntity(entity: TMetadataEntity | null): void {
		if (isEqual(this._entity$.value, entity))
			return;

		this.entity = entity;

		this._entity$.next(entity);
	}

	protected _rollbackEntity(): void {
		if (this.entitySetExternally === this.entity)
			return;

		this.entity = this.entitySetExternally;

		if (this.form && this.formScheme) {
			this._repopulateFormBasedOnScheme(
				this.form,
				this.formScheme,
				this.entity,
			);
		}
	}

	protected _setupForm(formScheme: FormScheme<TMetadataEntity>): void {
		this.setFormScheme(formScheme);

		this.form = this._buildFormGroupBasedOnFormScheme(
			formScheme,
			this.entity ?? this.factory(),
		);
	}

	protected _buildFormGroupBasedOnFormScheme<TFormSeedEntity extends MetadataEntity>(
		formScheme: FormScheme<TFormSeedEntity>,
		formSeedEntity: TFormSeedEntity,
	): UntypedFormGroup {
		this.__assertFormSchemeIsProvided(formScheme);

		return this._formBuilder.group(
			this.__buildFormGroupConfigBasedOnScheme(formScheme, formSeedEntity),
			this._formDefaultOptions,
		);
	}

	private __buildFormGroupConfigBasedOnScheme<TFormSeedEntity extends MetadataEntity>(
		formScheme: FormScheme<TFormSeedEntity>,
		formSeedEntity: TFormSeedEntity,
	): Record<string, any> {
		this.__assertFormSchemeIsProvided(formScheme);

		return mapValues(
			formScheme,
			(propertySchemeDefinition, propertyName) => this._buildControlConfigBasedOnPropertySchemeDefinition(
				get(formSeedEntity, propertyName),
				propertySchemeDefinition,
			),
		);
	}

	protected _buildControlConfigBasedOnPropertySchemeDefinition(
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		propertyValue: any,
		propertySchemeDefinition: FormScheme<any>[ string ],
	): UntypedFormArray | UntypedFormGroup | [ formState: any, opts?: FormSchemeAbstractControlOptions ] {

		if (isNil(propertySchemeDefinition))
			return [ propertyValue ];

		if (this.__isAbstractControlOptions(propertySchemeDefinition)) {
			return [
				isNil(propertyValue) && !isNil(propertySchemeDefinition.defaultValue)
					? propertySchemeDefinition.defaultValue
					: propertyValue,
				propertySchemeDefinition,
			];
		}

		if (this.__isArrayFormScheme(propertySchemeDefinition)) {
			return this._formBuilder.array(
				(<any[]> propertyValue).map(propertyArrayItem => this._buildControlConfigBasedOnPropertySchemeDefinition(
					propertyArrayItem,
					propertySchemeDefinition[1],
				)),
			);
		}

		if (isFunction(propertySchemeDefinition) || isArray(propertySchemeDefinition))
			return [ propertyValue, { validators: propertySchemeDefinition }];

		if (isPlainObject(propertySchemeDefinition))
			return this._buildFormGroupBasedOnFormScheme(propertySchemeDefinition, propertyValue);

		throw this.__buildError('Wrong property form scheme definition was provided');
	}

	private __isAbstractControlOptions(
		schemePropertyDefinition: any,
	): schemePropertyDefinition is FormSchemeAbstractControlOptions {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const definition: FormSchemeAbstractControlOptions = schemePropertyDefinition;

		return has(definition, 'validators')
			|| has(definition, 'asyncValidators')
			|| has(definition, 'updateOn')
			|| has(definition, 'defaultValue');
	}

	protected _repopulateFormBasedOnScheme<TFormEntity extends MetadataEntity>(
		form: UntypedFormGroup,
		formScheme: FormScheme<TFormEntity>,
		entity: TFormEntity | null,
	): void {
		this.__assertFormSchemeIsProvided(formScheme);

		forEach(
			formScheme,
			(propertySchemeDefinition, propertyName) => void this.__repopulatePropertyControlBasedOnPropertySchemeDefinition(
				form,
				propertyName,
				propertySchemeDefinition,
				entity,
			),
		);
	}

	protected _buildFormSchemeBasedOnPropertiesMetadata(propertiesMetadata: PropertyMetadata[]): FormScheme<any> {
		return Object.fromEntries(propertiesMetadata.map<[string, FormSchemeAbstractControlOptions]>(
			propertyMetadata => [
				propertyMetadata.property,
				{
					defaultValue: propertyMetadata.defaultPropertyValue,
				},
			],
		));
	}

	private __assertFormSchemeIsProvided(formScheme?: FormScheme<any>): void {
		if (isNil(formScheme))
			throw this.__buildError('The default behavior of the form entity base class requires the form scheme to be set on the constructor');
	}

	private __repopulatePropertyControlBasedOnPropertySchemeDefinition<TFormEntity extends MetadataEntity>(
		form: UntypedFormGroup,
		propertyName: string,
		propertySchemeDefinition: FormScheme<any>[ string ],
		entity: TFormEntity | null,
	): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const propertyValue = get(entity, propertyName);

		if (isPlainObject(propertySchemeDefinition)) {
			this._repopulateFormBasedOnScheme(
				<UntypedFormGroup> form.controls[propertyName],
				<FormScheme<any>> propertySchemeDefinition,
				propertyValue,
			);

			return;
		}

		if (this.__isArrayFormScheme(propertySchemeDefinition)) {
			form.setControl(propertyName, this._formBuilder.array(
				(<any[]> propertyValue).map(() => this._formBuilder.group({}, this._formDefaultOptions)),
			));

			return;
		}

		(<UntypedFormControl> form.controls[propertyName])
			.setValue(propertyValue, { emitEvent: false, emitModelToViewChange: true });
	}

	private __onFormGroupChangeBuildEntityAndEmitChange(): void {
		this.form$
			.pipe(
				switchMap(form => form
					? form.valueChanges
						.pipe(
							startWith(form.value),
							filter(() => form.enabled),
						)
					: EMPTY),
				map(this.__filterOutInternalControlsProperties()),
				filter(formValue => !isEmpty(formValue)),
				map(formValue => this.factory(<DTO<TMetadataEntity>>{
					...this.entity,
					...formValue,
				})),
				filter(formValueEntity => !isEqual(formValueEntity, this.entity)),
				takeUntilDestroyed(this),
			)
			.subscribe(v => void this._setEntity(v));
	}

	private __filterOutInternalControlsProperties() {
		return (formValue: Record<string, any>) => pickBy(
			formValue,
			(value, key) => !key.startsWith('__'),
		);
	}

	private __assertFactoryIsProvided(): void {
		if (isNil(this.factory))
			throw this.__buildError('Entity factory must be provided');
	}

	private __isArrayFormScheme(formScheme: unknown): formScheme is FormSchemeArray<unknown> {
		return isArray(formScheme) && formScheme.includes('array');
	}

	private __buildError(error: string): Error {
		return new Error(`${ this.constructor.name }: ${ error }`);
	}
}
