import { map, Observable, race, startWith, switchMap } from 'rxjs';

import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';

import { SLIDE } from '@bp/frontend/animations';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { CrmLead } from '@bp/frontend/domains/crm/leads/models';
import { CurrentCrmLeadFacade } from '@bp/frontend/domains/crm/leads/+current-crm-lead-state';
import { FORM_FIELD_DEFAULT_OPTIONS, IFormFieldDefaultOptions } from '@bp/frontend/components/core';

import { GetStartedFormComponent, GetStartedFormCrmLeadVm } from './components';

@Component({
	selector: 'bp-get-started-form-flow',
	standalone: true,
	imports: [
		AsyncPipe,

		GetStartedFormComponent,
	],
	templateUrl: './get-started-form-flow.component.html',
	styleUrls: [ './get-started-form-flow.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
	providers: [
		{
			provide: FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline',
				hideRequiredMarker: true,
				hideClearButton: true,
			} satisfies IFormFieldDefaultOptions,
		},
	],
})
export class GetStartedFormFlowComponent extends Destroyable {

	@Input() buttonText = 'Send';

	protected readonly _currentCrmLeadFacade = inject(CurrentCrmLeadFacade);

	private readonly __telemetry = inject(TelemetryService);

	private readonly __router = inject(Router);

	protected _entity = new GetStartedFormCrmLeadVm();

	// There is a flush timeout after saving request. Therefore, we need to use manual pending
	protected _pending$: Observable<boolean> = this._currentCrmLeadFacade.trySaveCrmLeadKeptInStore$.pipe(
		switchMap(
			() => race(
				this._currentCrmLeadFacade.saveCrmLeadSuccess$,
				this._currentCrmLeadFacade.saveCrmLeadFailure$,
			).pipe(
				map(() => false),
				startWith(true),
			),
		),
	);

	constructor() {
		super();

		this.__onSaveLeadSuccessRedirectToThankYou();
	}

	private __onSaveLeadSuccessRedirectToThankYou(): void {
		this._currentCrmLeadFacade.saveCrmLeadSuccess$
			.pipe(takeUntilDestroyed(this))
			.subscribe(({ request }) => void this._redirectToThankYou(request));
	}

	protected async _saveCrmLead(crmLead: CrmLead): Promise<void> {
		try {
			await this._currentCrmLeadFacade.updateAndSaveLeadKeptInStore(crmLead);
		} catch (error: unknown) {
			this.__telemetry.captureError(error);
		}
	}

	private _redirectToThankYou(lead: CrmLead): void {
		void this.__router.navigate([{
			outlets: {
				primary: [ 'thank-you', 'lead', { leadEmail: lead.email }],
				modal: null,
			},
		}]);
	}

}
