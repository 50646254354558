import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

import { DTO } from '@bp/shared/models/metadata';

import { SLIDE } from '@bp/frontend/animations';
import { CrmLeadType } from '@bp/frontend/domains/crm/leads/models';
import {
	ControlsSectionScheme, FormMetadataEntityBaseComponent, SharedComponentsMetadataModule
} from '@bp/frontend/components/metadata';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedFeaturesAnalyticsModule } from '@bp/frontend/features/analytics';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';

import { GetStartedFormCrmLeadVm } from './get-started-form-crm-lead.vm';
import { GetStartedFormSubmitBtnDirective } from './get-started-form-submit-btn.directive';

@Component({
	selector: 'bp-get-started-form',
	standalone: true,
	imports: [
		AsyncPipe,
		ReactiveFormsModule,
		NgTemplateOutlet,

		SharedComponentsCoreModule,
		SharedComponentsMetadataModule,
		SharedFeaturesAnalyticsModule,
		SharedDirectivesCoreModule,
	],
	templateUrl: './get-started-form.component.html',
	styleUrls: [ './get-started-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class GetStartedFormComponent extends FormMetadataEntityBaseComponent<GetStartedFormCrmLeadVm> {

	@Input() buttonText = 'Send';

	@Input() formError = false;

	@ContentChild(GetStartedFormSubmitBtnDirective)
	customSubmitBtn?: GetStartedFormSubmitBtnDirective;

	override onSubmitShowInvalidInputsToast = false;

	protected readonly _metadata = GetStartedFormCrmLeadVm.getClassMetadata();

	protected readonly _sectionScheme: ControlsSectionScheme<GetStartedFormCrmLeadVm> = [
		[ 'fullName' ],
		[ 'email' ],
		[ 'companyWebsite' ],
		[ 'phone' ],
	];

	constructor() {
		super();

		this._setupForm({
			fullName: null,
			email: null,
			companyWebsite: null,
			phone: null,
		});
	}

	override factory = (
		dto?: DTO<GetStartedFormCrmLeadVm>,
	): GetStartedFormCrmLeadVm => new GetStartedFormCrmLeadVm({
		...dto,
		type: CrmLeadType.customer,
	});

}
