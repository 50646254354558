<form class="promo-form" [formGroup]="form!" (ngSubmit)="submit()">
	<bp-property-metadata-controls-section
		floatLabel="never"
		[form]="form!"
		[metadata]="_metadata"
		[sectionScheme]="_sectionScheme"
		[staggerRendering]="false"
	/>

	@if (errors && errors.length > 0) {
		<div class="global-error mb-4" @slide>Something is wrong, please, try later</div>
	}

	@if (customSubmitBtn) {
		<ng-template [ngTemplateOutlet]="customSubmitBtn.templateRef" />
	} @else {
		<bp-pending-btn
			type="submit"
			btnClass="mat-flat-button promo-flat-button"
			bpGoogleTagOnClick="promo_get-started-form_cta_click"
			[disabled]="form!.disabled"
			[pending]="!!(pending$ | async)"
		>
			{{ buttonText }}
		</bp-pending-btn>
	}

	<div class="agree mt-3 text-center">
		By submitting this form, I agree to the
		<span class="nowrap">
			<a bpTargetBlank class="link" href="/terms-of-service">terms of service</a>
			&
			<a bpTargetBlank class="link" href="/privacy-policy">privacy policy</a>
		</span>
	</div>
</form>
